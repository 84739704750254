.apply-modal {
  .check-sign-container {
    height: 80vh;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 100px;
    color: rgb(38, 185, 38);
  }

  .modal-header {
    border-bottom: none;
  }

  .modal-body {
    display: flex;

    .img-container {
      width: 45%;
      display: flex;
      justify-items: center;
      align-items: center;

      img {
        width: 100%;
      }
    }

    .apply-form {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      gap: 15px;
      justify-content: center;
      align-items: center;

      .footer-text {
        font-size: 10px;
      }

      .footer-link {
        color: #ec407a;
        font-size: 10px;
      }

      .text-input {
        width: 80%;
      }

      .text-area {
        border: 1px solid rgba(0, 0, 0, 0.175);
        padding: 0.5em 1em;
        border-radius: 4px;
      }

      .checkbox-box {
        width: 80%;

        .MuiFormControlLabel-label {
          font-size: 10px;
        }
      }
    }
  }

  .modal-footer {
    border-top: none;

    .footer-text,
    .footer-text-link {
      margin-right: 680px;
      font-size: 8px;
      text-align: left;
      color: gray;
      margin-bottom: -10px;
    }

    .footer-link {
      color: #ec407a;
      font-size: 8px;
      text-decoration: underline;
    }
    .footer-text-mobile {
      display: none;
    }

    .submit {
      border-radius: 8px;
      margin: 0 6% 0 0;
    }
  }
}

@media (max-width: 1189px) {
  .apply-modal {
    .modal-footer {
      border-top: none;
      flex-direction: column;
      align-items: end;
      justify-content: end;
      margin-top: -20px;

      .footer-text-link {
        display: none;
      }

      .footer-link {
        color: #ec407a;
        font-size: 8px;
        text-decoration: underline;
      }

      .submit {
        border-radius: 8px;
        width: 18%;
        margin: 0 30% 0 0;
      }

      .mobile-footer {
        width: 62%;
        .footer-text-mobile {
          display: unset;
          text-align: left;
          font-size: 8px;
          color: gray;
        }

        .submit {
          border-radius: 8px;
          width: 18%;
          margin: 0 25% 0 0;
        }
      }
    }
  }
}

@media (max-width: 1024px) {
  .apply-modal {
    .modal-body {
      .img-container {
        display: none;
      }

      .apply-form {
        .footer-text {
          font-size: 13px;
        }

        .footer-link {
          font-size: 13px;
          text-decoration: underline;
        }

        .checkbox-box {
          padding: 0px;
          width: 80%;
          align-items: start;

          .MuiFormControlLabel-root {
            align-items: start;
            vertical-align: top;
          }

          .MuiCheckbox {
            vertical-align: top;
            align-items: start;
          }

          .MuiFormControlLabel-label {
            font-size: 14px;
            margin-left: 0px;
            vertical-align: top;
            align-items: start;
          }
        }
      }
    }

    .modal-footer {
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .footer-text-link {
        display: none;
      }

      .submit {
        border-radius: 8px;
        width: 85%;
        margin: auto;
      }

      .footer-text-mobile {
        display: unset;
        font-size: 14px;
        margin-right: unset;
        color: gray;

        .footer-link {
          font-size: 8px;
        }
      }
    }
  }

  .modal-footer {
    .mobile-footer {
      width: 80%;
      text-align: center;
      .footer-text-mobile {
        display: unset;
        font-size: 7.5px;
        text-align: unset;

        .footer-link {
          font-size: 7.5px;
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .apply-modal {
    .modal-body {
      padding: 0px;
      .img-container {
        display: none;
      }

      .apply-form {
        .footer-text {
          font-size: 10px;
        }

        .footer-link {
          font-size: 10px;
          text-decoration: underline;
        }

        .checkbox-box {
          padding: 0px;
          width: 80%;
          align-items: start;

          .MuiFormControlLabel-root {
            align-items: start;
            vertical-align: top;
          }

          .MuiCheckbox {
            align-items: start;
          }

          .MuiFormControlLabel-label {
            font-size: 10px;
            margin-left: 0px;
            vertical-align: top;
            align-items: start;
          }
        }
      }

      .footer-text-mobile {
        display: unset;
        font-size: 7.5px;
        margin-right: 40px;

        .footer-link {
          font-size: 7.5px;
        }
      }
    }

    .modal-footer {
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .footer-text-link {
        display: none;
      }

      .submit {
        margin: auto;
        width: 116px;
      }

      .mobile-footer {
        width: 80%;
        text-align: center;
        .footer-text-mobile {
          display: unset;
          font-size: 7.5px;
          text-align: unset;

          .footer-link {
            font-size: 7.5px;
          }
        }
      }
    }
  }
}

@media (max-width: 500px) {
  .apply-modal {
    .modal-body {
      padding: 0px;
      .apply-form {
        h2 {
          text-align: center;
          font-size: 16px;
          margin-bottom: 0px;
        }

        .footer-text {
          font-size: 10px;
        }

        .footer-link {
          font-size: 10px;
          text-decoration: underline;
        }

        .checkbox-box {
          padding: unset;
          width: 80%;

          input[type='checkbox'] {
            margin-top: -50%;
            vertical-align: middle;
          }

          .MuiFormControlLabel-label {
            font-size: 10px;
          }
        }
      }
    }

    .modal-footer {
      padding: 0px;
      margin-top: 5px;

      .footer-text-link {
        display: none;
      }

      .submit {
        width: 116px;
        margin: auto;
      }

      mobile-footer {
        width: 80%;
        padding-bottom: 15px;
        .footer-text-mobile {
          display: unset;
          font-size: 7.5px;

          .footer-link {
            font-size: 7.5px;
          }
        }
      }
    }
  }
}
