@keyframes moveRightToLeft {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}

@keyframes loading {
  from {
    background-color: #ec407a;
    width: 10%;
  }
  to {
    background-color: #ec407a;
    width: 100%;
  }
}

.slider-cards {
  width: 100%;
  display: flex;
  flex-direction: row;

  .card-content {
    text-align: left;
  }

  .slider-card {
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: center;
    background-color: #060e20;
    animation-duration: 7000ms;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
    width: 440px;

    .loading-bar {
      height: 16px;
      background-color: #ec407a;
      animation: loading 7000ms ease-in-out forwards infinite;
      position: absolute;
      top: -1px;
      left: 0px;
      width: 440px;
      border-top-left-radius: 100px;
      border-top-right-radius: 100px;
    }

    h2 {
      color: #ec407a;
      margin-bottom: 15px;
      font-size: 20px;
      font-weight: bold;
    }

    p {
      font-weight: bold;
      color: white;
      font-size: 15px;
    }
  }
}

@media (max-width: 1024px) {
  .slider-cards {
    .slider-card {
      padding: 30px 20px;

      p {
        font-size: 13px;
      }
    }
  }
}

@media (max-width: 768px) {
  .slider-cards {
    .slider-card {
      p {
        font-size: 14px;
      }
    }
  }
}

@media (max-width: 500px) {
  .slider-cards {
    width: 100%;
    display: flex;
    flex-direction: row;
  
    .card-content {
      text-align: left;
    }
  
    .slider-card {
      display: flex;
      flex-direction: column;
      align-items: center;
      background-color: #060e20;
      margin-left: 0px;
      animation-duration: 8000ms;
      animation-iteration-count: infinite;
      animation-timing-function: ease-in-out;
      width: 100%;
      height: 340px;
      
      h2 {
        color: #ec407a;
        margin-bottom: 15px;
      }
  
      p {
        font-weight: bold;
        color: white;
        font-size: 14px;
      }
    }
  }

}