.form-input-and-button {
    .rounded-input {
        border-radius: 6px 0 0 6px;
        padding: 1rem 2rem;
    }

    .rounded-button {
        border-radius: 0 6px 6px 0;
        padding-left: 2em;
        padding-right: 2em;
        background-color: #ec407a;
        background-image: linear-gradient(180deg,rgba(255,255,255,0.15),rgba(255,255,255,0));
        border-color: #ec407a;
        border: 1px solid transparent;
        color: white;

        &:hover {
            background-color: #e06f95;
        }
    }
}

@media (max-width: 767px) {
    .form-input-and-button {
        margin: 0px 25px;
    }
    
}