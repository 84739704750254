.charte-page {
  .charte-container {
    position: relative;
    width: 95%;
    margin: auto;
    margin-top: 100px;
    text-align: left;

    .title {
      text-align: left;
      padding-top: 70px;
      padding-bottom: 35px;
      font-weight: bolder;
    }

    .link {
      color: #ec407a;
      text-decoration: underline;
    }

    a {
      color: #ec407a;
      text-decoration: underline;
    }

    .main-table {
      border-collapse: collapse;
      width: 100%;

      th,
      td {
        border: 0.5px solid gray;
        padding: 8px;
        text-align: left;
        width: 33%;
        overflow-wrap: break-word;
        word-break: break-all;
      }

      .merged-cell {
        border: none;
        border-right: 0.5px solid gray;
      }

      .nested-table-row {
        border-collapse: collapse;
        padding: unset;

        .merged-cell {
          border-left: 0.5px solid gray;
        }

        .table-bordered {
          th,
          td {
            // border: 0.5px solid gray;
            text-align: left;
          }

          .first-column {
            width: 16.5%;
          }
          .second-column {
            width: 16.5%;
          }

          .third-column {
            width: 33%;
          }

          .fourth-column {
            width: 33%;
          }
        }
      }

      .nested-table-row-2 {
        padding: unset;
        border: none;
        .table-bordered {
          th,
          td {
            text-align: left;
          }

          .first-column {
            width: 16.5%;
            border-right: 0.5px solid gray;
          }
          .second-column {
            width: 16.5%;
          }

          .third-column {
            width: 33%;
          }
          .fourth-column {
            width: 33%;
          }
        }
      }
      
      .nested-table-row-3 {
        .second-column {
          width: 18.5%;
        }

        .third-column {
          width: 33%;
        }

        .fourth-column {
          width: 33%;
        }
      }
    }
  }

  h6 {
    text-decoration: underline;
    padding-top: 5px;
    font-weight: bold;
  }

  p {
    padding-top: 5px;
  }

  .bold-text {
    font-weight: bold;
  }

  li {
    padding-bottom: 5px;
  }
}

@media (max-width: 820px) {
  .charte-page {
    .charte-container {
      .table-container {
        width: 100%;
        overflow-y: scroll;
        margin-left: -15px;

        .main-table {
          width: 747px;
        }
      }
    }
  }
}
