.yaja-button {
  border-radius: 8px;
  border: 1px solid transparent;
  padding: 5px 10px;
  text-decoration: none;
  color: white;
  background-color: #ec407a;
  font-weight: 600;
  text-align: center;

  &:hover {
    cursor: pointer;
    color: white;
    background-color: #ec40799f;
  }
}

.disabled {
  background-color: #ccc;
}
