.all-rights {
  color: grey;
  font-size: 12px;
  margin-top: 20px;
}

.borderless-accordion {
  display: none;
}

.desktop-footer-content {
  width: 90%;
  padding: 28px 2em;
  margin: auto;
  display: flex;
  text-align: left;
  border-top: 1px solid rgb(218, 218, 218);

  * {
    font-size: 1rem;
  }

  .footer-section {
    width: 25%;

    .contact-container {
      margin-left: 15px;
      margin-top: 20px;
    }

    .section-body {
      padding: 0 1.25rem;
    }

    .logo {
      width: 80%;
      max-width: 170px;
      padding-top: 5px;

      &:hover {
        cursor: pointer;
      }
    }

    .custom-link {
      text-decoration: none;
      color: #717684;
    }

    .contact-button {
      background-color: white;
      color: #0e7fce;
      border-radius: 6px;
      border: 1px solid #0e7fce;
    }

    .footer-column-title {
      font-weight: 800;
      color: #717684;
      margin-bottom: 15px;
      padding: 1.25rem;
    }

    .footer-column-link {
      color: #717684;
      margin-bottom: 10px;
      display: block;
    }
  }
}

#yaja-footer {
  display: flex;
  justify-content: center;

  .accordion {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .accordion {
    .card-header button {
      border: none !important;
    }
  }

  .accordion-item {
    width: 300px;
    border: none;
    border-radius: 0;
    margin-bottom: 0;
  }

  .borderless-accordion {
    .accordion-header {
      border: none;
      background-color: transparent;
    }
  }

  .borderless-accordion {
    .accordion-body {
      border: none;
    }
  }

  .accordion-button:not(.collapsed) {
    color: #ffffff;
    background-color: #ffffff;
    box-shadow: #ffffff;
  }

  .accordion-button {
    .arrow-icon {
      display: none;
    }
  }

  .accordion-button:focus {
    outline: none;
    box-shadow: none;
  }

  .accordion-button:not(.collapsed)::after {
    display: none;
  }

  .accordion-button::after {
    display: none;
  }

  .accordion-item:focus {
    outline: none;
    box-shadow: none;
  }

  .custom-link {
    text-decoration: none;
    color: #717684;
  }

  .footer-content {
    display: flex;
    text-align: left;
    width: 93%;
    padding-bottom: 3em;
    border-top: 1px solid rgb(218, 218, 218);
    padding-top: 28px;

    .footer-column {
      width: 25%;

      .logo {
        width: 80%;
        max-width: 170px;

        &:hover {
          cursor: pointer;
        }
      }
      .contact-container {
        margin-left: 15px;
        margin-top: 20px;
      }

      .contact-button {
        background-color: white;
        color: #0e7fce;
        border-radius: 6px;
        border: 1px solid #0e7fce;
      }

      .footer-column-title {
        font-weight: 800;
        color: #717684;
        margin-bottom: 15px;
      }

      a {
        display: block;
      }

      .footer-column-link {
        color: #717684;
        margin-bottom: 10px;
      }
    }
  }
}

@media (max-width: 1024px) {
  .accordion-button {
    .arrow-icon {
      display: none;
    }
  }

  .accordion-button:not(.collapsed) {
    color: #ffffff;
    background-color: #ffffff;
    box-shadow: #ffffff;
  }

  .borderless-accordion {
    .accordion-item {
      width: 300px;
      border: none;
      border-radius: 0;
      margin-bottom: 0;
    }
  }

  .borderless-accordion {
    .accordion-header {
      border: none;
      background-color: transparent;
    }
  }

  .borderless-accordion {
    .accordion-body {
      border: none;
    }
  }
  .accordion-button {
    .arrow-icon {
      display: none !important;
    }
  }
  .accordion-button:focus {
    outline: unset !important;
    box-shadow: none;
  }

  .accordion-button:not(.collapsed)::after {
    display: none !important;
  }

  .accordion-button::after {
    display: none;
  }

  .accordion-item:focus {
    outline: none;
    box-shadow: none;
  }

  #yaja-footer {
    .custom-link {
      text-decoration-color: #717684;
      color: #717684;
    }

    .footer-content {
      display: flex;
      text-align: left;
      padding-top: 3em;
      padding-bottom: 3em;
      border-top: 1px solid rgb(218, 218, 218);

      .footer-column {
        width: 25%;
        .logo {
          padding-top: 5px;
          margin-bottom: 13px;
        }

        .footer-column-title {
          white-space: nowrap;
          font-weight: 800;
          margin-bottom: 15px;
          color: rgb(102, 102, 102);
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .all-rights {
    font-size: 10px;
    margin-top: 10px;
  }

  .desktop-footer-content {
    display: none;
  }

  .accordion {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .accordion {
    .card-header button {
      border: none !important;
    }
  }

  .borderless-accordion {
    .accordion-item {
      width: 300px;
      border: none;
      border-radius: 3px;
      margin-bottom: 0;
    }
  }

  .accordion-item {
    width: 300px;
    border: none;
    border-radius: 0;
    margin-bottom: 0;
  }

  .borderless-accordion {
    .accordion-header {
      border: none;
      background-color: transparent;
    }
  }

  .borderless-accordion {
    .accordion-body {
      border: none;
    }
  }

  .accordion-button:not(.collapsed) {
    color: #ffffff;
    background-color: #ffffff;
    box-shadow: #ffffff;
  }
  .accordion-button {
    .arrow-icon {
      display: inline !important;
      margin-right: -10px;
    }
  }

  .accordion-button:focus {
    outline: none;
    box-shadow: none;
  }

  .accordion-button:not(.collapsed)::after {
    display: inline !important;
    margin-right: -10px;
  }

  .accordion-button::after {
    display: inline !important;
    margin-right: -10px;
  }

  .accordion-item:focus {
    outline: auto;
    box-shadow: none;
  }

  #yaja-footer {
    display: flex;
    width: 405px;

    .custom-link {
      text-decoration-color: #717684;
      color: #717684;
    }

    .footer-content {
      display: table-column;
      text-align: left;
      width: 95%;
      padding-top: 3em;
      padding-bottom: 3em;
      border-top: 1px solid rgb(218, 218, 218);
      .footer-column {
        width: 100%;
      }
      .image {
        margin-bottom: 20px;

        .logo {
          width: 35%;
        }

        .footer-column-title {
          font-weight: 800;
          margin-bottom: 15px;
          color: rgb(102, 102, 102);
        }
      }
    }
  }
}

@media (max-width: 500px) {
  .accordion {
    display: flex;
  }

  .accordion-button {
    .arrow-icon {
      display: inline !important;
      margin-right: -10px;
    }
  }

  .accordion-button:focus {
    outline: none;
    box-shadow: none;
    margin-right: -10px;
  }

  .accordion-button:not(.collapsed)::after {
    display: inline !important;
    margin-right: -10px;
  }

  .accordion-button::after {
    display: inline !important;
    margin-right: -10px;
  }

  .accordion-item:focus {
    outline: none;
    box-shadow: none;
  }

  #yaja-footer {
    display: flex;
    width: 405px;

    .custom-link {
      color: #717684;
    }

    .footer-content {
      display: table-column;
      text-align: left;
      width: 95%;
      padding-top: 3em;
      padding-bottom: 3em;
      border-top: 1px solid rgb(218, 218, 218);

      .footer-column {
        width: 100%;

        .image {
          margin-bottom: 20px;
        }
        .logo {
          width: 35%;
        }

        .footer-column-title {
          font-weight: 800;
          margin-bottom: 15px;
        }
      }
    }
  }
}
