#home-page {
  position: relative;

  .cloud-1-parallax {
    position: absolute;
    top: 500px;
    left: 100px;
  }

  .section-title {
    max-width: 900px;
    margin-bottom: 50px;

    .section-title-intro {
      font-size: 40px;
      font-weight: 700;
      // color: #ec407a;
    }

    .section-title-description {
      font-size: 40px;
      font-weight: 700;
      color: #030a1c;
    }
  }

  #top-container {
    position: relative;
    height: 100vh;

    .top-gradient {
      display: flex;
      position: absolute;
      top: 0;
      z-index: 700;
      height: 100vh;
      width: 100%;
      background: transparent;
      align-items: center;
      justify-content: center;
    }

    .top-form-container {
      display: flex;
      flex-direction: column;
      max-width: 600px;
      margin-top: 2em;

      h1 {
        font-size: 4em;
        font-weight: 700;
        color: white;
      }

      img {
        width: 30%;
      }

      p {
        color: white;
        font-weight: 600;
      }
    }

    @-webkit-keyframes yMover {
      0% {
        transform: translateY(0);
      }
      100% {
        transform: translateY(-20px);
      }
    }

    @keyframes yMover {
      0% {
        transform: translateY(0);
      }
      100% {
        transform: translateY(-20px);
      }
    }

    @-webkit-keyframes xMover {
      0% {
        transform: translateX(0);
      }
      100% {
        transform: translateX(-20px);
      }
    }

    @keyframes xMover {
      0% {
        transform: translateX(0);
      }
      100% {
        transform: translateX(-20px);
      }
    }
  }

  .second-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 5em;
    padding-bottom: 40px;

    .section-title-description {
      margin-bottom: 0;
    }

    .section-title {
      margin-bottom: 5em;
    }

    .section-2-content {
      display: flex;
      flex-direction: row;
      text-align: left;
      width: 100%;
      position: relative;
    }

    .section-2-content-cards {
      display: flex;
      flex-direction: row;
      text-align: left;
      gap: 40px;
    }

    .section-2-card {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      border: 0px solid rgba(0, 0, 0, 0.08);
      border-radius: 8px;
      background-color: #f7f7f7;
      padding: 0px;
      width: 100%;

      &.left-card {
        margin-left: 40px;
      }

      &.right-card {
        margin-right: 40px;
      }

      .text-container {
        padding: 20px;
      }

      .image-card-1 {
        width: 100%;
        border-radius: 0 0 8px 8px;
      }

      .image-card-2 {
        width: 100%;
        border-radius: 0 0 8px 8px;
      }

      .icon-container {
        background-color: rgba(14, 126, 206, 0.388);
        display: flex;
        justify-content: center;
        align-items: center;
        color: #0e7fce;
        border-radius: 8px;
        width: 50px;
        height: 50px;
        padding: 5px;
        margin-bottom: 30px;
      }

      .card-title {
        font-size: 23px;
        color: #ec407a;
        font-weight: 800;
        padding-bottom: 20px;
      }

      .card-subtitle {
        font-weight: 800;
        font-size: 25px;
        margin-bottom: 30px;
      }

      .card-description {
        font-size: 17px;
        color: gray;
      }
    }
  }

  .third-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 5em;
    background-size: 50px, 40px, 30px;
    background-position: 45px 200px, 1290px 430px, 700px 460px, 200px 600px;

    .third-section-container {
      width: 1024px;

      .third-section-title {
        width: 100%;
        text-align: left;

        h2 {
          font-size: 40px;
          font-weight: 700;
          text-align: center;
          margin-bottom: 50px;
        }

        h3 {
          font-weight: 700;
          font-size: 16px;
          color: white;
          margin-top: 35px;
        }

        p {
          font-size: 40px;
          font-weight: 700;
          color: #030a1c;
        }
      }

      .work-card {
        margin-bottom: 20px;
      }

      .third-section-content {
        display: flex;
        flex-direction: row;
        margin-top: 30px;
        width: 100%;
        justify-content: space-between;


        .slider-cards {
          width: 45%;
          height: 377px;
        }
      }

      .section-3-content {
        display: flex;
        flex-direction: row;
        width: 80%;
        gap: 50px;
        padding-bottom: 50px;
        text-align: left;

        .left-container {
          width: 30%;
          position: relative;

          .main-img {
            max-width: 343px;
            height: auto;
            border-radius: 16px;
            width: 100%;
          }
        }

        .right-container {
          width: 60%;

          .subtitle {
            color: #ec407a;
          }

          ul {
            list-style: none;
            margin: 0;
            padding: 0;

            li {
              margin: 1em auto 0.4em 1.9em;
              line-height: 1.55;
              color: #717684;

              &:before {
                content: '';
                position: absolute;
                margin-left: -1.9em;
                display: flex;
                left: 0px;
                top: 6px;
                width: 20px;
                height: 20px;
              }
            }
          }
        }
      }
    }
  }

  .fourth-section {
    text-align: left;
    display: flex;
    justify-content: center;
    padding-top: 5em;
    padding-bottom: 5em;
    background-size: 65px, 30px, 30px;
    background-position: 45px 200px, 1300px bottom;
    background-color: #060e20;

    .fourth-section-card {
      width: 1024px;
      position: relative;
      border-radius: 0px;

      .icon-container {
        display: flex;
        align-items: center;
        color: #0e7fce;
        border-radius: 8px;
        width: 100%;
        height: 50px;
        padding: 5px;

        &.first {
          justify-content: start;
        }

        &.second {
          justify-content: end;
        }

        .icon-quote {
          height: 25px;
        }
      }

      h3 {
        font-weight: 700;
        font-size: 22px;
        color: #ec407a;
      }

      p {
        color: white;
        font-weight: 700;
        font-size: 20px;
        margin-bottom: 0;
      }

      .parallax-python-container {
        position: absolute;
        right: 100px;
        bottom: -30px;

        .parallax-python-img {
          width: 60px;
        }
      }
    }
  }

  .fifth-section {
    text-align: left;
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding-top: 5em;
    padding-bottom: 5em;

    .fifth-section-content {
      width: 80%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      gap: 20px;

      .strip-container {
        display: flex;
        width: 70%;
        padding: 20px;
        gap: 20px;
        align-self: flex-start;

        &.right-aligned {
          float: right;
          align-self: flex-end;
        }

        .icon-container {
          background-color: #ec407953;
          display: flex;
          justify-content: center;
          align-items: center;
          color: #ec407a;
          border-radius: 8px;
          width: 80px;
          height: 80px;
          padding: 5px;
          font-size: 30px;
        }

        .text-container {
          .box-title {
            color: black;
            font-weight: 800;
          }

          .box-desc {
            font-size: 18px;
            color: rgb(91, 94, 118);
          }
        }
      }
    }
  }

  .clients-testimonies-section {
    width: 100%;
    margin: 7em auto 0 auto;
    text-align: left;

    .clients-testimonies-section-title {
      width: 80%;
      text-align: left;

      h3 {
        font-weight: 700;
        font-size: 16px;
        color: #ec407a;
      }

      p {
        font-size: 40px;
        font-weight: 700;
        color: #030a1c;
      }
    }

    .slider-container {
      justify-content: center;
      align-items: center;
      flex: 1;
      background-color: #fbc4d3;

      .container-client {
        display: flex;
        position: relative;

        .description-container {
          background-color: #122182;
          padding: 80px 40px;
          width: 180%;

          .header {
            font-size: 26px;
          }

          .rounded-button {
            color: whitesmoke;
            background-color: #5875eb;
            border-color: #122182;
          }

          .client-reviews {
            border-radius: 8px;
            border: 1px solid transparent;
            padding: 5px 10px;
            text-decoration: none;
            color: white;
            background-color: #0e7fce;
            font-weight: 600;
            justify-content: center;
            align-items: center;
            display: flex;
            margin-bottom: 36px;

            .read-text {
              margin-left: 5px;
            }
          }

          .google-text {
            color: #ffede2;
            font-size: 20px;
          }
          .review {
            color: #ffede2;
            font-size: 16px;
            margin-right: 9px;
          }
        }

        .mySwiper {
          padding-top: 80px;
        }

        .company-card {
          height: 200px;
          background-color: white;
          width: 100%;
          gap: 20px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;

          .card-content {
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            text-align: center;
            padding: 10px;
          }

          .card-content img {
            max-width: 100%;
            max-height: 100%;
            object-fit: contain;
            width: 30%;
          }

          img {
            width: 100%;
            max-height: 180px;

            &.xmanna-img {
              width: 65%;
            }
          }
        }
      }
    }
  }

  .sixth-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 5em;
    padding-bottom: 5em;
    padding-left: 40px;
    padding-right: 40px;
    background-size: 50px, 30px, 30px;
    background-position: 1200px 8px;

    .sixth-section-title {
      width: 80%;

      h3 {
        font-weight: 700;
        font-size: 16px;
        color: #ec407a;
      }
    }

    .sixth-section-content {
      width: 100%;
      display: flex;
      gap: 20px;
      position: relative;

      .click-container {
        cursor: pointer;
      }

      .box-container {
        width: 24%;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 20px;

        .icon-container {
          background-color: #ec407953;
          display: flex;
          justify-content: center;
          align-items: center;
          color: #ec407a;
          border-radius: 8px;
          width: 50px;
          height: 50px;
          padding: 5px;
          margin-bottom: 15px;
        }

        .box-title {
          color: black;
          font-weight: 800;
          margin-bottom: 15px;
          font-size: 15px;
        }

        .box-desc {
          font-size: 14px;
          color: rgba(91, 94, 118, 1);
        }
      }

      .parallax-js-container {
        position: absolute;
        left: -30px;
        top: 160px;

        .parallax-js-img {
          width: 60px;
        }
      }
    }
  }
}

@media (max-width: 1024px) {
  #home-page {
    #top-container {
      .top-form-container {
        h1 {
          font-size: 40px;
        }
        p {
          font-size: 18px;
        }
      }
    }

    .second-section {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-top: 2em;

      .section-2-content {
        display: flex;
        flex-direction: row;
        text-align: left;
        width: 100%;
        position: relative;
      }

      .section-2-content-cards {
        display: flex;
        flex-direction: row;
        text-align: left;
      }

      .section-2-card {
        border: 0px solid rgba(0, 0, 0, 0.08);
        border-radius: 5px;
        background-color: #f7f7f7;
        padding: 0px;

        .text-container {
          padding: 20px;

        }

        .image-card-1 {
          border-radius: 0 0 5px 5px;
        }

        .image-card-2 {
          border-radius: 0 0 5px 5px;
        }

        .icon-container {
          background-color: rgba(14, 126, 206, 0.388);
          display: flex;
          justify-content: center;
          align-items: center;
          color: #0e7fce;
          border-radius: 8px;
          width: 50px;
          height: 50px;
          padding: 5px;
          margin-bottom: 30px;
        }

        .card-title {
          font-size: 20px;
          color: #ec407a;
          font-weight: 800;
          padding-bottom: 20px;
        }

        .card-subtitle {
          font-weight: 800;
          font-size: 25px;
          margin-bottom: 30px;
        }

        .card-description {
          font-size: 17px;
          color: gray;
        }
      }
    }

    .third-section {
      .third-section-container {
        width: 768px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;

        .third-section-content {
          width: 100%;

          .image-gallery {
            width: unset;
            min-width: unset;

            .image-gallery-slide-wrapper {
              width: 100%;
            }
          }

          .slider-cards {
            height: 310px;
          }
        }

        .third-section-title {
          width: 95%;
          h2 {
            font-size: 23px;
            margin-left: 0;
          }
          p {
            font-size: 25px;
          }
        }
      }
      .section-3-content {
        flex-direction: column;

        .left-container {
          width: 100%;
        }
        .right-container {
          width: 100%;
          align-self: flex-start;
          width: fit-content;
        }
      }
    }

    .fourth-section {
      .fourth-section-card {
        width: 100%;
        border-radius: 0px;
      }
    }

    .fifth-section {
      .fifth-section-content {
        width: 90%;

        .strip-container {
          width: 85%;
          padding: 15px;

          .text-container {
            .box-title {
              margin-bottom: 3px;
            }

            .box-desc {
              font-size: 14px;
              margin-bottom: 0;
            }
          }

          .icon-container {
            height: 30px;
            width: 30px;
            font-size: 14px;
          }
        }
      }
    }

    .clients-testimonies-section {
      .clients-testimonies-section-title {
        p {
          font-size: 25px;
        }
      }

      .slider-container {
        justify-content: center;
        align-items: center;
        height: unset;
        flex: 1;
        background-color: #fbc4d3;

        .container-client {
          display: flex;
          flex-direction: column;

          div {
            border: unset;
          }

          .description-container {
            height: 320px;
            width: 100%;
          }
          .mySwiper {
            width: 100%;
            margin: unset;
            padding-top: 25px;
            padding-bottom: 25px;
          }

          .company-card {
            background-color: white;
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            height: 250px;

            .card-content {
              width: 100%;
              height: 100%;
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
              text-align: center;
              padding: 10px;
            }
            img {
              width: 100%;
              margin: auto;
            }
          }
        }
      }
    }

    .sixth-section {
      .sixth-section-content {
        flex-direction: column;
        align-items: center;

        .box-container {
          width: 365px;
        }
      }
    }
  }
}

@media (max-width: 990px) {
  #home-page {
    #top-container {
      .top-form-container {
        h1 {
          font-size: 40px;
        }
        p {
          font-size: 18px;
        }
      }
    }

    .second-section {
      padding-bottom: 40px;

      .section-title {
        margin-bottom: 15px;
        padding-left: 40px;
        padding-right: 40px;

        .section-title-description {
          font-size: 20px;
        }
      }

      .section-2-card {
        background-color: #f7f7f7;

        .card-subtitle {
          font-size: 20px;
        }
      }

      .section-2-content {
        display: flex;
        flex-direction: row;
        justify-content: center;
        text-align: left;
        gap: 5px;
        width: 100%;
        position: relative;
        padding-left: 40px;
        padding-right: 40px;
      }

      .section-2-content-cards {
        display: flex;
        flex-direction: row;
        text-align: left;
        gap: 40px;
      }

      .section-2-card {
        background-color: #f7f7f7;

        .image-card-1 {
          border-radius: 0 0 5px 5px;
        }

        .image-card-2 {
        }

        &.left-card {
          margin-left: 0px;
        }
  
        &.right-card {
          margin-right: 0px;
        }

        .icon-container {
          background-color: rgba(14, 126, 206, 0.388);
          display: flex;
          justify-content: center;
          align-items: center;
          color: #0e7fce;
          border-radius: 8px;
          width: 50px;
          height: 50px;
          padding: 5px;
          margin-bottom: 30px;
        }

        .card-title {
          color: #ec407a;
          font-weight: 800;
        }

        .card-subtitle {
          font-weight: 800;
          font-size: 25px;
          margin-bottom: 30px;
        }

        .card-description {
          font-size: 15px;
          color: #717684;
        }
      }

      .section-2-content-non-moving-cards {
        display: none;
      }
    }

   

    .fourth-section {
      .fourth-section-card {
        width: 100%;
        border-radius: 0px;
      }
    }

    .fifth-section {
      .fifth-section-content {
        width: 90%;

        .strip-container {
          width: 85%;
          padding: 15px;

          .text-container {
            .box-title {
              margin-bottom: 3px;
            }

            .box-desc {
              font-size: 14px;
              margin-bottom: 0;
            }
          }

          .icon-container {
            height: 30px;
            width: 30px;
            font-size: 14px;
          }
        }
      }
    }

    .clients-testimonies-section {
      .clients-testimonies-section-title {
        p {
          font-size: 25px;
        }
      }

      .slider-container {
        justify-content: center;
        align-items: center;
        height: unset;
        flex: 1;
        background-color: #fbc4d3;

        .container-client {
          display: flex;
          flex-direction: column;

          div {
            border: unset;
          }

          .description-container {
            height: 320px;
            width: 100%;
          }
          .mySwiper {
            width: 100%;
            margin: unset;
            padding-top: 25px;
            padding-bottom: 25px;
          }

          .company-card {
            background-color: white;
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            height: 250px;

            .card-content {
              width: 100%;
              height: 100%;
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
              text-align: center;
              padding: 10px;
            }
            img {
              width: 100%;
              margin: auto;
            }
          }
        }
      }
    }

    .sixth-section {
      .sixth-section-content {
        flex-direction: column;
        align-items: center;

        .box-container {
          width: 365px;
        }
      }
    }
  }
}

@media (max-width: 768px) {
  #home-page {
    #top-container {
      .top-form-container {
        h1 {
          font-size: 30px;
        }
        p {
          font-size: 13px;
        }
      }
    }

    .second-section {
      background-image: none;
      padding-bottom: 30px;

      .section-title {
        max-width: 85%;
        margin-bottom: 30px;

        .section-title-description {
          font-size: 23px;
          font-weight: 700;
          color: #030a1c;
        }
      }
      .section-2-content {
      }

      .section-2-card {
        background-color: #f7f7f7;
        margin: unset;

        .icon-container {
          width: 60px;
          height: 60px;
          margin: auto;
          margin-bottom: 10px;
        }
        .card-subtitle {
          font-size: 20px;
        }
      }

      .section-2-content-cards {
        flex-direction: column;
        gap: 30px;
        align-items: center;

        .section-2-card {
          // width: 95%;

          &.left-card {
            margin-left: 0;
          }

          &.right-card {
            margin-right: 0;
          }

          .image-card-1 {
          }

          .image-card-2 {
          }
        }
      }
    }

    .third-section {
      background-image: none;

      .third-section-container {
        .third-section-content {
          width: unset;
          justify-content: center;

          .image-gallery {
            display: none;
          }

          .slider-cards {
            width: 100%;
          }
        }

        .third-section-title {
          width: unset;

          h2 {
            font-size: 23px;
          }
          p {
            font-size: 25px;
            margin: 0px;
          }
        }
      }

      .section-3-content {
        gap: unset;
        padding: unset;
        .left-container {
          text-align: right;
        }
        .right-container {
          width: fit-content;
        }
      }
    }

    .fourth-section {
      background-image: none;
      padding-top: 3em;
      padding-bottom: unset;

      .fourth-section-card {
        width: 90%;
        padding-left: 0;
        padding-right: 0;
        border-radius: 0px;
      }
    }

    .fifth-section {
      padding-bottom: unset;

      .fifth-section-content {
        width: 90%;

        .strip-container {
          width: 85%;
          padding: 15px;

          .text-container {
            .box-title {
              margin-bottom: 3px;
            }

            .box-desc {
              font-size: 14px;
              margin-bottom: 0;
            }
          }

          .icon-container {
            height: 30px;
            width: 30px;
            font-size: 14px;
          }
        }
      }
    }

    .clients-testimonies-section {
      background-image: none;

      .clients-testimonies-section-title {
        p {
          font-size: 25px;
        }
      }

      .slider-container {
        justify-content: center;
        align-items: center;
        flex: 1;
        background-color: #fbc4d3;

        .container-client {
          display: flex;
          flex-direction: column;

          div {
            padding: unset;
            border: unset;
          }

          .description-container {
            width: 100%;
            height: fit-content;
          }
          .mySwiper {
            width: 100%;
          }

          .company-card {
            padding: 20px;
            background-color: white;
            width: 100%;
          }
        }
      }
    }

    .sixth-section {
      background-image: none;

      .sixth-section-content {
        .box-container {
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
        }
      }
    }
  }
}

@media (max-width: 500px) {
  #home-page {
    #top-container {
      .top-form-container {
        padding: 5px;
        h1 {
          font-size: 30px;
          margin-left: 25px;
          margin-right: 25px;

        }
        p {
          font-size: 13px;
          margin-left: 25px;
          margin-right: 25px;
        }
      }
    }
    .second-section {
      padding-top: 50px;

      .section-title {
        max-width: 90%;
        padding-left: 0;
        padding-right: 0;
        margin-bottom: 50px;

        .section-title-description {
          font-size: 23px;
          font-weight: 700;
          color: #030a1c;
        }
      }

      .section-2-content {
        padding-left: 0;
        padding-right: 0;
      }

      .section-2-card {
        .icon-container {
          margin: auto;
          margin-bottom: 10px;
          width: 60px;
          height: 60px;
        }
        .card-subtitle {
          font-size: 20px;
        }

        .image-card-1 {
          width: 100%;
       
        }
        .image-card-2 {
          width: 100%;
        }
      }

      .section-2-content-cards {
        flex-direction: column;
        gap: 50px;
        width: 90%;
        position: relative;
      }
    }

    .third-section {
      background-image: none;
      padding-top: 50px;

      .third-section-container {
        width: 100%;

        .third-section-title {
          margin-top: 10px;
          width: unset;
          margin-bottom: 8px;

          h2 {
            font-size: 25px;
          }
          p {
            font-size: 25px;
            margin: 0px;
          }
        }

        .third-section-content {
          width: 90%;
          margin-bottom: 50px;
          margin-top: 0;

          .app-image-gallery {
            display: none;
          }
        }
      }

      .section-3-content {
        gap: unset;
        padding: unset;
        .left-container {
          text-align: right;
        }
        .right-container {
          width: fit-content;
        }
      }
    }

    .fifth-section {
      .fifth-section-content {
        width: 90%;

        .strip-container {
          width: 85%;
          padding: 15px;

          .text-container {
            .box-title {
              margin-bottom: 3px;
            }

            .box-desc {
              font-size: 14px;
              margin-bottom: 0;
            }
          }

          .icon-container {
            height: 30px;
            width: 30px;
            font-size: 14px;
          }
        }
      }
    }

    .clients-testimonies-section {
      background-image: none;
      .clients-testimonies-section-title {
        p {
          font-size: 25px;
        }
      }

      .slider-container {
        justify-content: center;
        align-items: center;
        flex: 1;
        background-color: #fbc4d3;

        .container-client {
          display: flex;
          flex-direction: column;

          div {
            padding: unset;
            border: unset;
          }

          .description-container {
            width: 100%;
            padding: 45px 25px;
            height: fit-content;

            .header {
              font-size: 25px;
            }
            .rounded-button {
              width: fit-content;
              text-align: left;
              .text-button {
                font-size: 14px;
              }
            }
          }
          .mySwiper {
            width: 100%;
            padding-bottom: 45px;
            padding-top: 45px;
          }
          .cards-container {
            margin-bottom: 20px;
            background-color: #fbc4d3;
            height: 270px;

            .company-card {
              padding: 40px;
              height: 400px !important;

              img {
                width: 50%;
                margin: auto;
                margin-top: 10px;
              }
              p {
                margin-top: 1em;
              }
            }
          }
        }
      }
    }

    .sixth-section {
      background-image: none;
      padding-left: 0;
      padding-right: 0;
      padding-top: 50px;
      
      .section-title {
        margin-top: 0;
        width: unset;
        margin-bottom: 50px;

        h3 {
          font-size: 25px;
          margin-bottom: 0;
        }
      }

      .sixth-section-content {
        width: 90%;
        
        .box-container {
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
        }
      }
    }
  }
}

@media (max-width: 390px) {
  #home-page {
    #top-container {
      .top-form-container {
        padding: 5px;
        h1 {
          font-size: 30px;
        }
        p {
          font-size: 13px;
        }
      }
    }
    .second-section {
      .section-title {
        max-width: 85%;
        margin-bottom: 50px;

        .section-title-description {
          font-size: 23px;
          font-weight: 700;
          color: #030a1c;
        }
      }

      .section-2-card {
        
        .icon-container {
          margin: auto;
          margin-bottom: 10px;
          width: 60px;
          height: 60px;
        }
        .card-subtitle {
          font-size: 20px;
        }
      }

      .section-2-content-cards {
        flex-direction: column;
        gap: 50px;
        width: 90%;
        position: relative;
      }
    }

    .third-section {
      background-image: none;

      .third-section-container {
        width: 100%;

        .third-section-title {
          margin-top: 10px;
          width: unset;
          margin-bottom: 8px;

          h2 {
            font-size: 25px;
          }
          p {
            font-size: 25px;
            margin: 0px;
          }
        }

        .third-section-content {
          width: unset;
          margin-bottom: 50px;

          .app-image-gallery {
            display: none;
          }
        }
      }

      .section-3-content {
        gap: unset;
        padding: unset;
        .left-container {
          text-align: right;
        }
        .right-container {
          width: fit-content;
        }
      }
    }

    .fifth-section {
      .fifth-section-content {
        width: 90%;

        .strip-container {
          width: 85%;
          padding: 15px;

          .text-container {
            .box-title {
              margin-bottom: 3px;
            }

            .box-desc {
              font-size: 14px;
              margin-bottom: 0;
            }
          }

          .icon-container {
            height: 30px;
            width: 30px;
            font-size: 14px;
          }
        }
      }
    }

    .clients-testimonies-section {
      background-image: none;
      .clients-testimonies-section-title {
        p {
          font-size: 25px;
        }
      }

      .slider-container {
        justify-content: center;
        align-items: center;
        flex: 1;
        background-color: #fbc4d3;

        .container-client {
          display: flex;
          flex-direction: column;

          div {
            padding: unset;
            border: unset;
          }

          .description-container {
            width: 100%;
            padding: 45px;
            height: fit-content;

            .header {
              font-size: 25px;
            }
            .rounded-button {
              width: fit-content;
              text-align: left;
              .text-button {
                font-size: 14px;
              }
            }
          }
          .mySwiper {
            width: 100%;
            padding-bottom: 45px;
            padding-top: 45px;
          }
          .cards-container {
            margin-bottom: 20px;
            background-color: #fbc4d3;
            height: 270px;

            .company-card {
              padding: 40px;
              height: 400px !important;

              img {
                width: 50%;
                margin: auto;
                margin-top: 10px;
              }
              p {
                margin-top: 1em;
              }
            }
          }
        }
      }
    }

    .sixth-section {
      background-image: none;
      .sixth-section-content {
        .box-container {
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
        }
      }
    }
  }
}

@media (max-width: 300px) {
  #home-page {
    #top-container {
      .top-form-container {
        padding: 5px;
        h1 {
          font-size: 30px;
        }
        p {
          font-size: 13px;
        }
      }
    }
    .second-section {
      .section-title {
        max-width: 85%;
        margin-bottom: 50px;

        .section-title-description {
          font-size: 23px;
          font-weight: 700;
          color: #030a1c;
        }
      }

      .section-2-card {
        .icon-container {
          width: 60px;
          height: 60px;
        }
        .card-subtitle {
          font-size: 20px;
        }
      }
      .section-2-content-cards {
        flex-direction: column;
        gap: 50px;
        width: 90%;
      }

      .section-2-card {
        width: 100%;
        height: unset;
      }
    }

    .third-section {
      .third-section-container {
        .third-section-content {
          margin-top: unset;
        }
      }
      .third-section-title {
        margin-top: 20px;
        width: unset;
        font-size: 17px;
        p {
          font-size: 25px;
          margin: 0px;
        }
      }

      .section-3-content {
        gap: unset;
        padding: unset;
        .left-container {
          text-align: right;
        }
        .right-container {
          width: fit-content;
        }
      }
    }

    .fifth-section {
      .fifth-section-content {
        width: 90%;

        .strip-container {
          width: 85%;
          padding: 15px;

          .text-container {
            .box-title {
              margin-bottom: 3px;
            }

            .box-desc {
              font-size: 14px;
              margin-bottom: 0;
            }
          }

          .icon-container {
            height: 30px;
            width: 30px;
            font-size: 14px;
          }
        }
      }
    }

    .clients-testimonies-section {
      .clients-testimonies-section-title {
        p {
          font-size: 25px;
        }
      }

      .slider-container {
        justify-content: center;
        align-items: center;
        width: 115%;
        flex: 1;
        background-color: #fbc4d3;

        .container-client {
          display: flex;
          flex-direction: column;

          div {
            padding: unset;
            border: unset;
          }

          .description-container {
            width: 100%;
            height: fit-content;

            .header {
              font-size: 20px;
            }
            .sub-header {
              font-size: 12px;
            }
            .rounded-button {
              color: whitesmoke;
            }
          }
          .mySwiper {
            width: 100%;
          }
          .cards-container {
            margin-bottom: 20px;
            background-color: #fbc4d3;

            .company-card {
              padding: 40px;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 3;
              -webkit-box-orient: vertical;

              img {
                width: 25%;
                margin: auto;
                margin-top: 10px;
              }
              p {
                margin-top: 1em;
              }
            }
          }
        }
      }
    }

    .sixth-section {
      .sixth-section-content {
        .box-container {
          width: fit-content;
          display: flex;
          flex-direction: column;
          align-items: center;
        }
      }
    }
  }
}
