@font-face {
  font-family: 'Poppins';
  src: url(./fonts/Poppins/Poppins-Regular.ttf);
  font-display: swap;
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins';
  src: url(./fonts/Poppins/Poppins-Thin.ttf);
  font-display: swap;
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins';
  src: url(./fonts/Poppins/Poppins-ThinItalic.ttf);
  font-display: swap;
  font-weight: 100;
  font-style: italic;
}
@font-face {
  font-family: 'Poppins';
  src: url(./fonts/Poppins/Poppins-ExtraLight.ttf);
  font-display: swap;
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins';
  src: url(./fonts/Poppins/Poppins-ExtraLightItalic.ttf);
  font-display: swap;
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: 'Poppins';
  src: url(./fonts/Poppins/Poppins-Light.ttf);
  font-display: swap;
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins';
  src: url(./fonts/Poppins/Poppins-LightItalic.ttf);
  font-display: swap;
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Poppins';
  src: url(./fonts/Poppins/Poppins-Medium.ttf);
  font-display: swap;
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins';
  src: url(./fonts/Poppins/Poppins-MediumItalic.ttf);
  font-display: swap;
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Poppins';
  src: url(./fonts/Poppins/Poppins-SemiBold.ttf);
  font-display: swap;
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins';
  src: url(./fonts/Poppins/Poppins-SemiBoldItalic.ttf);
  font-display: swap;
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: 'Poppins';
  src: url(./fonts/Poppins/Poppins-BoldItalic.ttf);
  font-display: swap;
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: 'Poppins';
  src: url(./fonts/Poppins/Poppins-Bold.ttf);
  font-display: swap;
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins';
  src: url(./fonts/Poppins/Poppins-ExtraBold.ttf);
  font-display: swap;
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins';
  src: url(./fonts/Poppins/Poppins-ExtraBoldItalic.ttf);
  font-display: swap;
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: 'Poppins';
  src: url(./fonts/Poppins/Poppins-Black.ttf);
  font-style: normal;
  font-weight: 900;
}

@font-face {
  font-family: 'Poppins';
  src: url(./fonts/Poppins/Poppins-BlackItalic.ttf);
  font-style: italic;
  font-weight: 900;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  font-family: 'Poppins';
}
