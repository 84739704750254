.offers-swiper {
    text-align: left;

    .swiper-wrapper {
        align-items: stretch;
    }

    .swiper-slide {
        // flex: 1 1 0;
        // max-width: 280px;
        // height: unset;
        // display: flex;
    }

    .swiper-button-next, .swiper-rtl .swiper-button-prev{
        display: none;
    }

    .swiper-button-prev {
        display: none;
    }
    
    .yaja-card {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        h3 {
            font-size: 18px;
            margin-bottom: 20px;
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 1; /* number of lines to show */
                    line-clamp: 1; 
            -webkit-box-orient: vertical;
        }

        h4 {
            font-size: 14px;
        }

        .multi-details {
            display: flex;
            font-weight: 500;
            font-size: 10px;
            gap: 10px;
            flex-wrap: wrap;

            .details {
                margin-left: 5px;
                margin-bottom: 0;
                display: inline-block;

                .details-icon {
                    color: #4c7eea;
                }
            }
        }

        .offer-company-description {
            padding-top: 5px;
            font-size: 12px;
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 1; /* number of lines to show */
                    line-clamp: 1; 
            -webkit-box-orient: vertical;
        }

        .categories-container {
            display: flex;
            gap: 10px;
            margin-top: 10px;
            margin-bottom: 10px;

            .category-container {
                background-color: rgb(233, 233, 233);
                padding: 2px 5px;
                font-weight: bold;
                border-radius: 4px;
                font-size: 8px;
            }
        }

        .see-offer-btn {
            display: block;
            margin-top: 20px;
            width: fit-content;
            border-radius: 9px;
        }

        .btn-container {

        }
    }
}
