$details-color: rgb(159, 166, 168);
$title-color: rgb(102, 102, 102);

#offer-page {
    #top-section {
        position: relative;
        height: 50vh;
        margin-top: 57px;
        background-image: url("../../images/offer-page-bg.jpg");
        background-position: 0 -200px;
        background-repeat: no-repeat;
        background-size: cover;
    }

    #content-section {
        text-align: left;
        padding: 20px 0;
        display: flex;
        justify-content: center;

        .content-container {
            width: 700px;
            display: flex;
            padding-left: 20px;
            padding-right: 20px;

            .side-section {
                width: 200px;
                display: none;

                .apply-btn {
                    width: 100%;
                    border-radius: 8px;
                }

                .share-container {
                    margin-top: 20px;
                    text-align: center;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    color: $details-color;

                    .share-title {
                        font-size: 14px;
                        font-weight: 700;
                    }

                    .icons {
                        display: flex;
                        gap: 20px;

                        .brand-icon {}
                    }
                }
            }

            .offer-content {
                flex: 1;

                .offer-title {
                    font-weight: 400;
                    font-size: 30px;
                    color: $title-color;
                }

                .placeholder-offer-title {
                    width: 300px;
                    height: 25px;
                    margin-bottom: 15px;
                    display: block;
                }

                .top-section {
                    display: flex;
                    justify-content: space-between;
                }

                .company-name {
                    font-size: 15px;
                    margin-bottom: 20px;
                    color: $title-color;
                    font-weight: 600;
                }

                .date {
                    font-size: 12px;
                    color: $details-color;
                    margin-bottom: 5px;
                }

                .description-title {
                    font-size: 25px;
                    color: $details-color;
                    margin-bottom: 20px;
                }

                .placeholder-description {
                    width: 100%;
                    display: block;
                    margin-bottom: 10px;
                }

                .multi-details {
                    display: flex;
                    flex-wrap: wrap;
                    color: rgb(97 97 97);
                    font-weight: 500;
                    gap: 10px;

                    .details {
                        margin-left: 5px;
                        margin-bottom: 0;
                        display: inline-block;
                        font-size: 13px;

                        .details-icon {
                            color: #4c7eea;
                        }
                    }
                }

                .categories-container {
                    display: flex;
                    gap: 10px;
                    margin-top: 20px;
                    margin-bottom: 20px;

                    .category-container {
                        background-color: rgb(233, 233, 233);
                        padding: 5px 10px;
                        font-weight: bold;
                        color: $details-color;
                        border-radius: 4px;
                        font-size: 14px;
                    }
                }

                .description {
                    color: $title-color;
                    font-size: 16px;

                    h1 {
                        font-size: 20px;
                        font-weight: 800;
                    }

                    h2 {
                        font-weight: 700;
                        font-size: 18px;
                    }

                    h3 {
                        font-weight: 700;
                        font-size: 16px;
                    }

                    h4 {
                        font-weight: 700;
                        font-size: 14px;
                    }

                    h5 {
                        font-size: 14px;
                    }
                }
            }
        }

        .sticky-side-section {
            width: 200px;

            .side-section {
                width: 200px;
                position : sticky;
                top: 100px;

                .apply-btn {
                    width: 100%;
                    border-radius: 8px;
                }

                .share-container {
                    margin-top: 20px;
                    text-align: center;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    color: $details-color;

                    .share-title {
                        font-size: 14px;
                        font-weight: 700;
                    }

                    .icons {
                        display: flex;
                        gap: 20px;

                        .brand-icon {}
                    }
                }
            }
        }
    }

    #other-offers-section {
        padding-top: 50px;
        display: flex;
        justify-content: center;
        margin-bottom: 50px;

        #other-offers-content {
            width: 900px;

            h2 {
                font-size: 20px;
                margin-bottom: 25px;
            }
        }
    }
}

@media(max-width: 1024px) {
    #offer-page {
        #top-section {
            background-position: unset;
        }

        #content-section {
            .content-container {
                .side-section {
                    display: block;
                }
            }

            .sticky-side-section {
                display: none;
            }

            .offer-content {
                flex: 1;

                .offer-title {
                    font-weight: 400;
                    font-size: 30px;
                }
            }
        }

    }

}

@media(max-width: 768px) {
    #offer-page {
        #top-section {
           margin-bottom: 14px;
        }

        .top-section-two {
            padding-left: 10px;
           }
        
        #content-section {
            padding: 0px;

            .content-container {
                .offer-content {
                    .offer-title {
                        font-size: 18px;
                    }

                    .description-title {
                        font-size: 18px;
                        padding-left: 10px;

                    }

                    .multi-details {
                        color: $details-color;
                        font-weight: 500;
                        font-size: 10px;

                        .details {
                            margin-left: 5px;
                            margin-bottom: 0;
                            display: inline-block;

                            .details-icon {
                                color: #4c7eea;
                            }
                        }
                    }
                }

                .side-section {

                    padding-left: 35px;

                    .apply-btn {
                       border-radius:8px;
                    }

                    .share-container {
                        margin-top: 3px;

                        .share-title {
                            font-size: 12px;
                        }
                    }
                }
            }

            .offer-content {
                flex: 1;

                .offer-title {
                    font-weight: 400;
                    font-size: 30px;
                }

                .multi-details {
                    color: $details-color;
                    font-weight: 500;
                    font-size: 10px;

                    .details {
                        margin-left: 5px;
                        margin-bottom: 0;
                        display: inline-block;

                        .details-icon {
                            color: #4c7eea;
                        }
                    }
                }

            }
        }

        #other-offers-section {
            padding-top: 5px;
            display: flex;
            justify-content: center;
            margin-bottom: 50px;

            #other-offers-content {
                width: 393px;

                h2 {
                    font-size: 20px;
                    margin-bottom: 25px;
                }
            }
        }
    }
}

@media(max-width: 500px) {
    #offer-page {
        #top-section {
            height: 25vh;
            background-position: unset;
        }

        #content-section {
            .content-container {
                width: 100%;
                padding-left: 20px;
                padding-right: 20px;

                .offer-content {
                    width: 100%;
                    flex: 1;
    
                    .placeholder-offer-title {
                        width: 160px;
                    }
    
                    .offer-title {
                        font-weight: 400;
                        font-size: 30px;
                    }

                    .description-title {
                        font-size: 18px;
                    }
                }
            }
        }
    }

    #other-offers-section {
        #other-offers-content {
            width: 393px;

            h2 {
                font-size: 20px;
                margin-bottom: 25px;
            }
        }
    }

}