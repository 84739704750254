.yaja-navbar {
    backdrop-filter: blur(5px);
    background-color: rgba(248,249,250, 0.3) !important;
    font-weight: 600;
    padding-left: 40px;
    padding-right: 40px;

    .nav-content {
        img {
            width: 120px;
        }
    
        .contact-us {
            background-color: #0e7fce;
            color: white;
            border-radius: 6px;
            margin-left: 8px;
        }
    }
}

@media(max-width: 991px) {
    .yaja-navbar {
        .nav-content {
            display: flex;
            justify-content: space-between;
            width: 100%;
            
            .contact-us{
                background-color: unset;
                color: rgba(0, 0, 0, 0.55);
            }
        }
    }
}

@media (max-width: 500px) {
    .yaja-navbar {
        padding-left: 0;
        padding-right: 0;
        display: flex;
        justify-content: center;

        .nav-content {
            width: 90%;
        }
    }
}
